import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import 'materialize-css/dist/css/materialize.css';
import './App.css';

import Calculator from './Calculator';

type Props = {};

export default function App(_props: Props) {
    return (
        <Router>
            <Switch>
                <Route path="/" component={Calculator} />
            </Switch>
        </Router>
    );
}
