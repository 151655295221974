import React, { useState } from 'react'
import { default as CurrencyFormat } from 'react-number-format';

import { Money } from './Money';
import Fraction from './Fraction';

export default function Percent(props: { value: Fraction }) {
    return <CurrencyFormat
        className="percent"
        value={props.value.toPercentage()}
        displayType={'text'}
        decimalScale={1}
        suffix={'%'}
    />;
}
