import React from 'react'
import { default as NumberFormat } from 'react-number-format';

import { Money } from './Money';

export default function Kronor(props: { value: Money }) {
    return <NumberFormat
        className="kronor"
        value={props.value as number}
        displayType={'text'}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator=' '
        decimalSeparator=','
        suffix={' kr'}
    />;
}
