import { Money } from './Money'
import Fraction from './Fraction'

export type Inputs = {
    price: Money,
    existingCollateral: Money,
    availableCash: Money,
    interest: Fraction,
    householdGrossIncome: Money,
}

export type Costs =
    {
        newCollaterals: Money,
        collateralCosts: Money,
        collateralFee: Money,
        registrationTax: Money,
        registrationFee: Money,
        cashDeposit: Money,
        loan: Money,
        loanShare: Fraction,
        total: Money,
    }


export function calculateCosts(inputs: Inputs): Costs {
    const registrationTaxFraction = new Fraction(0.015);
    const collateralTaxFraction = new Fraction(0.02);

    let registrationTax: Money = registrationTaxFraction.scale(inputs.price);
    let registrationFee = 825;
    let collateralFee = 375;
    let cashBeforeCollateralCosts = inputs.availableCash - registrationTax - registrationFee - collateralFee;
    let newCollaterals = Math.max(0, inputs.price - cashBeforeCollateralCosts - inputs.existingCollateral);
    let collateralCosts = collateralTaxFraction.scale(newCollaterals);
    let cashDeposit = cashBeforeCollateralCosts - collateralCosts;
    let loan = Math.max(0, inputs.price - cashDeposit);
    let loanShare = new Fraction(loan / inputs.price);
    let total = cashDeposit + collateralFee + collateralCosts + registrationTax + registrationFee;
    return {
        newCollaterals,
        collateralCosts,
        collateralFee,
        registrationTax,
        registrationFee,
        cashDeposit,
        loan,
        loanShare,
        total,
    }
}

export type LoanCosts = {
    mortgageRate: Fraction,
    mortgagePerYear: Money,
    mortgagePerMonth: Money,
    interestPerYear: Money,
    interestPerMonth: Money,
    totalPerMonth: Money,
    shareOfIncome: Fraction,
}

export function calculateLoanCosts(inputs: Inputs, costs: Costs): LoanCosts {
    var mortgageRate: Fraction;
    if (costs.loanShare.toPercentage() >= 0.7) {
        mortgageRate = new Fraction(0.02);
    } else if (costs.loanShare.toPercentage() >= 5) {
        mortgageRate = new Fraction(0.01);
    } else {
        mortgageRate = new Fraction(0);
    }
    if (costs.loan > (inputs.householdGrossIncome * 12 * 4.5)) {
        mortgageRate = mortgageRate.plus(new Fraction(0.01));
    }
    let mortgagePerYear = mortgageRate.scale(costs.loan);
    let interestPerYear = inputs.interest.scale(costs.loan);
    let mortgagePerMonth = mortgagePerYear / 12;
    let interestPerMonth = interestPerYear / 12;

    let totalPerMonth = mortgagePerMonth + interestPerMonth;
    return {
        mortgageRate,
        mortgagePerYear,
        interestPerYear,
        mortgagePerMonth,
        interestPerMonth,
        totalPerMonth,
        shareOfIncome: new Fraction(totalPerMonth / inputs.householdGrossIncome),
    }
}
