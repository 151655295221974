import React, { useState } from 'react'
import { default as NumberFormat, NumberFormatValues } from 'react-number-format';

import './NumberInputRow.css';

type Props = {
    label: string,
    initialValue: number,
    decimalScale?: number,
    fixedDecimalScale?: boolean,
    suffix?: string,
};

export default function NumberInputRow(props: Props): [number, React.ReactNode] {
    const [value, setValue] = useState(props.initialValue);
    const onChange = (values: NumberFormatValues) => {
        setValue(values.floatValue);
    };
    const markup = (<div className="row">
        <div className="col s12 input-field">
            <label>{props.label}</label>
            <NumberFormat
                className="number-input"
                value={value as number}
                decimalScale={props.decimalScale}
                fixedDecimalScale={props.fixedDecimalScale}
                thousandSeparator=' '
                decimalSeparator=','
                suffix={props.suffix}
                allowNegative={false}
                onValueChange={onChange}
            />
        </div>
    </div>)

    return [value, markup]
}
