export default class Fraction {
    private fraction: number;

    constructor(fraction: number) {
        if (fraction < 0 || fraction > 1) {
            throw Error(`Invalid fraction: ${fraction}`)
        }
        this.fraction = fraction;
    }

    scale(n: number): number {
        return n * this.fraction;
    }

    toPercentage(): number {
        return this.fraction * 100;
    }

    plus(f: Fraction): Fraction {
        return new Fraction(f.fraction + this.fraction)
    }
}

