import React from 'react';
import queryString from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import M from 'materialize-css';

import ShareLink from './ShareLink';
import NumberInputRow from './NumberInputRow';
import Percentage from './Percentage';
import Kronor from './Kronor';
import { Money } from './Money';
import Fraction from './Fraction';
import { Inputs, Costs, LoanCosts, calculateCosts, calculateLoanCosts } from './costs';

type Props = {
    history: any,
    location: { search: string },
};

type InitialInputs = {
    price?: Money,
    existingCollateral?: Money,
    availableCash?: Money,
    interest?: number,
    householdGrossIncome?: Money,
}

export default function Calculator(props: Props) {
    var initialInputs: InitialInputs = parseInputs(props.location.search);

    const [price, priceRow] = NumberInputRow({
        label: 'Köpeskilling',
        initialValue: initialInputs.price || 1000000,
        decimalScale: 2,
        fixedDecimalScale: true,
        suffix: ' kr',
    });
    const [existingCollateral, existingCollateralRow] = NumberInputRow({
        label: 'Existerande pantbrev',
        initialValue: initialInputs.existingCollateral || 0,
        decimalScale: 2,
        fixedDecimalScale: true,
        suffix: ' kr',
    });
    const [availableCash, availableCashRow] = NumberInputRow({
        label: 'Tillgängliga kontanter',
        initialValue: initialInputs.availableCash || 150000,
        decimalScale: 2,
        fixedDecimalScale: true,
        suffix: ' kr',
    });
    const [interest, interestRow] = NumberInputRow({
        label: 'Räntesats',
        initialValue: initialInputs.interest || 1.5,
        decimalScale: 2,
        fixedDecimalScale: true,
        suffix: '%',
    });
    const [householdGrossIncome, householdGrossIncomeRow] = NumberInputRow({
        label: 'Månadsinkomst (brutto)',
        initialValue: initialInputs.householdGrossIncome || 25000,
        decimalScale: 2,
        fixedDecimalScale: true,
        suffix: ' kr',
    });

    let inputs: Inputs = {
        price,
        existingCollateral,
        availableCash,
        interest: new Fraction(interest / 100),
        householdGrossIncome
    };

    const currentUri = renderUri(inputs);

    React.useEffect(() => {
        props.history.push(currentUri);
    }, [price, existingCollateral, availableCash, interest, householdGrossIncome]);

    let costs = calculateCosts(inputs);
    let loanCosts = calculateLoanCosts(inputs, costs);

    return (
        <div className="container">
            <div className="row">
                <div className="col s12">
                    <h1>Bolån-kalkylatorn</h1>
                    <p className="subtitle grey-text">Räkna ut engångs- och månadskostnader för ditt bolån</p>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m6">
                    <HeadingRow label="Invärden" icon="📝" />
                    <div className="row" >
                        <div className="col s12">
                            <form>
                                {priceRow}
                                {existingCollateralRow}
                                {availableCashRow}
                                {interestRow}
                                {householdGrossIncomeRow}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col s12 offset-m1 m5 costs">
                    <HeadingRow label="Lån" icon="🏦" />
                    <CalculatedRow label="Nya pantbrev" helpLink="https://www.bostadslån.com/vad-%C3%A4r-pantbrev">
                        <Kronor value={costs.newCollaterals} />
                    </CalculatedRow>
                    <CalculatedRow label="Lånesumma">
                        <Kronor value={costs.loan} />
                    </CalculatedRow>
                    <CalculatedRow label="Belåningsgrad" helpLink="https://www.sambla.se/sambla-forklarar/belaningsgrad/">
                        <Percentage value={costs.loanShare} />
                    </CalculatedRow>
                    <CalculatedRow label="Amorteringskrav" helpLink="https://www.fi.se/sv/finansiell-stabilitet/hushallens-skulder/information-om-bolan-fran-fi.se/fragor-och-svar-om-skarpt-amorteringskrav-for-hushall-med-stora-skulder/">
                        <Percentage value={loanCosts.mortgageRate} />
                    </CalculatedRow>
                    <HeadingRow label="Engångskostnader" icon="💰" />
                    <CalculatedRow label="Stämpelskatt (pantbrev)">
                        <Kronor value={costs.collateralCosts} />
                    </CalculatedRow>
                    <CalculatedRow label="Avgift (pantbrev)">
                        <Kronor value={costs.collateralFee} />
                    </CalculatedRow>
                    <CalculatedRow label="Stämpelskatt (lagfart)">
                        <Kronor value={costs.registrationTax} />
                    </CalculatedRow>
                    <CalculatedRow label="Avgift (lagfart)">
                        <Kronor value={costs.registrationFee} />
                    </CalculatedRow>
                    <CalculatedRow label="Kontantinsats">
                        <Kronor value={costs.cashDeposit} />
                    </CalculatedRow>
                    <HeadingRow label="Månadskostnader" icon="📅" />
                    <CalculatedRow label="Amortering">
                        <Kronor value={loanCosts.mortgagePerMonth} />
                    </CalculatedRow>
                    <CalculatedRow label="Ränta">
                        <Kronor value={loanCosts.interestPerMonth} />
                    </CalculatedRow>
                    <CalculatedRow label="Total">
                        <Kronor value={loanCosts.totalPerMonth} />
                    </CalculatedRow>
                    <CalculatedRow label="Andel av inkomst">
                        <Percentage value={loanCosts.shareOfIncome} />
                    </CalculatedRow>
                </div>
            </div>
            <div className="row">
                <div className="col s12 center">
                    <CopyToClipboard text={window.location.origin + currentUri} onCopy={() => {
                        M.toast({ html: 'Länk till analys kopierad!' })
                    }}>
                        <button className="btn">Kopiera länk till kalkyl</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="row">
                <div className="col s12 grey-text">
                    Skapad av <a href="https://wickstrom.tech">Oskar Wickström</a> med <a href="https://gitlab.com/owickstrom/bolan-kalkylatorn/">öppen källkod</a>.
                </div>
            </div>
        </div >
    )
}

function CalculatedRow(props: { label: string, children: React.ReactNode, helpLink?: string }) {
    const helpLink = props.helpLink
        ? <a className="help-link" target="_blank" href={props.helpLink}>?</a>
        : null;
    return (
        <div className="row calculated">
            <div className="col s5 label">{props.label}{helpLink}</div>
            <div className="col s7 value orange-text text-darken-3">{props.children}</div>
        </div>)
}


function HeadingRow(props: { label: string, icon: string }) {
    return (
        <div className="row" >
            <div className="col s12">
                <h2>{props.label} <i>{props.icon}</i></h2>
            </div>
        </div>
    )
}

function parseInputs(s: string): InitialInputs {
    try {
        const obj: any = queryString.parse(s);
        return {
            price: parseFloat(obj['price']),
            existingCollateral: parseFloat(obj['existingCollateral']),
            availableCash: parseFloat(obj['availableCash']),
            interest: parseFloat(obj['interest']),
            householdGrossIncome: parseFloat(obj['householdGrossIncome']),
        }
    } catch {
        return {};
    }
}

function renderUri(inputs: Inputs): string {
    return '/?' + queryString.stringify({ ...inputs, interest: inputs.interest.toPercentage() });
}
